import React, { Component } from "react";
import img from "../../assets/images/complete.svg";
import bgSliders from "../../helpers/bgSliders";
import h from "../../helpers/helpers";
import "./SubscribeCompleteKoreqPage.scss";
import SubscribeCompleteKoreqPageDisplay from "./SubscribeCompleteKoreqPage.styled";
import { Link } from 'react-router-dom';
import { saveTracker } from "../../helpers/agencyTracker";
import queryString from "query-string";

const randomBg = bgSliders.getRandomBg();

// Function to report conversion
function gtag_report_conversion() {
  let tracker_value = localStorage.getItem('tracker');
  if (localStorage.getItem(tracker_value) !== '1') {
    window.gtag('event', 'conversion', {
      'send_to': 'AW-619421651/C_ScCIHe_qcZENO_rqcC'
    });
    localStorage.setItem(tracker_value, '1');
  }
  return false;
}

// Tracker function
const tracker = async (GMSISDN) => {
  var datag = {};
  if (localStorage.getItem('tracker')) {
    datag = {
      key: 'parameter',
      company: localStorage.getItem("trackerCompany"),
      value: localStorage.getItem('tracker'),
      identifierKey: 'id',
      identifierValue: 'EbMD6FMZhU',
      phoneNum: GMSISDN
    };
  }
  await saveTracker(datag);
};

export default class SubscribeCompleteKoreqPage extends Component {
  async componentDidMount() {
    
    const data = queryString.parse(window.location.search);
    
      
    if (data.msisdn) {
      await tracker(data.msisdn);
    }
    gtag_report_conversion();

    await h.wait(5000);
    this.props.history.push("/login");
  }

  render() {
    return (
      <SubscribeCompleteKoreqPageDisplay bgImg={randomBg}>
        <div className="formPage">
          <div className="formsLayout">
            <div className="header">
              <Link to="/">
                <div className="logo" />
              </Link>
            </div>
            <div className="center">
              <img src={img} alt="Complete" />
            </div>
            <div className="footer">
  <div>
    <p>تم الاشتراك بنجاح</p>
  </div>
  <div>
    
  تم ارسال تفاصيل الدخول فى رسالة ويتم تحويلك تلقائيًا خلال 5 ثوانٍ لتسجيل الدخول    
    
    
    </div>
  <div>
    <a href="/login">اضغط هنا</a>
  </div>
</div>

          </div>
        </div>
      </SubscribeCompleteKoreqPageDisplay>
    );
  }
}

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import FloatingAlert from "../../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../../helpers/bgSliders";
import "./SignupPage.scss";
import Ksa3AnetSignupPageDisplay from "./SignupPage.styled";
import { setLoginData } from "../../../helpers/helpers";
import { Trans, withTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import { setAgencyData, saveTracker } from "../../../helpers/agencyTracker";
import queryString from "query-string";
import * as localLang from "../../../i18n/i18n";
import { subscribe } from "../NcellSignupPage/services";


const randomBg = bgSliders.getRandomBg();
function Ksa3AnetSignupPage({ t }) {
  const [activeData, setActiveData] = useState("");
  const [transactionidentify,setTransactionidentify] = useState("");
  const [checkPhone, setCheckPhone] = useState(false);

  const [showverif, setShowverif] = useState(false);
  const [showSms, setShowSMS] = useState(false);
  const [verifyCode, setVerifyCode] = useState("");
  const [errorMesg, setErrorMesg] = useState(false);
  const [verfiErrorMesg, setVerfiErrorMesg] = useState(false);
  const [errorresverify, seterrorresverify] = useState("");

  const [errorMesgSubscribe, setErrorMesgSubscribe] = useState("");
  const [signupPlan, setSignupPlan] = useState("d");
  const [otpid, setotpid] = useState("");
  const [showLoading, setshowLoading] = useState(false);



  const history = useHistory();
  const [alert, setAlert] = useState(false);


  const plans = [
    {
      plan: "d",
      name: "Daily"
    },
    {
      plan: "w",
      name: "Weekly"
    }
  ];

  useEffect(() => {
    //yousry lang
   //console.log("yousry lang");
    const params = queryString.parse(window.location.search);
    if (params.lang) {
     //console.log("lang",params.lang);
      localStorage.setItem("i18nextLng", params.lang);
      if(localStorage.getItem("i18nextLng") != params.lang){
        localStorage.setItem("i18nextLng", params.lang);
        window.location.reload();
      }
      //window.location.reload();
      //localLang.checklocalLanguage();
    }

    const urlsearch = window.location.search;
    if (urlsearch !== "") {
     //console.log("tracker")
      setAgencyData(urlsearch);
    }
  });


  const operatorNetwork = async (GMSISDN) => {
    console.log(GMSISDN);
    setActiveData(GMSISDN);
    if (GMSISDN.startsWith("966054") || GMSISDN.startsWith("966056")){
      setShowSMS(false);   
    }else if(GMSISDN.startsWith("966050") || GMSISDN.startsWith("966053") || GMSISDN.startsWith("966055")){
      setShowSMS(true);
    }    
  }
    // save tracker
    const tracker = async (GMSISDN) => {
      var datag = {};
      if (localStorage.getItem('tracker')) {
        var op_id = 'dwMDLMIekh';
       
    
        datag = {
          key: 'parameter',
          company: localStorage.getItem("trackerCompany"),
          value: localStorage.getItem('tracker'),
          identifierKey: 'id',
          identifierValue: op_id,
          phoneNum: GMSISDN
        };
       //console.log(datag)
  
      }
      await saveTracker(datag);
  
    }
    const Subscribe = async (e) => {
      e.preventDefault();
      
      if (!activeData) {
        setErrorMesgSubscribe("Phone number is required.");
        setErrorMesg(true);
        return;
      }
    
      const phon_n = activeData.toString();
      
      // Validate phone number
      const validPatterns = /^(54|56|054|056)/;
      if (!validPatterns.test(phon_n)) {
        setErrorMesgSubscribe("برجاءادخال رقم موبيلي");
        setErrorMesg(true);
        return;
      }
    
      if (!showSms) {
        const url = `http://api.playit.mobi/api/v2/3anet-saudi/send-otp?msisdn=${phon_n}&plan=d`;
        setshowLoading(true);
        try {
          const res = await axios.get(url);
          if (res.data.status === true) {
            if (res.data.user_id) {
              setLoginData(
                res.data.user_id,
                res.data.token,
                res.data.user_name,
                res.data.photo,
                res.data.operator_id,
                res.data.user_email,
                res.data.msisdn
              );
            }
            if (res.data.otpid) {
              setotpid(res.data.otpid);
            }
            setShowverif(true);
          } else {
            setErrorMesgSubscribe(res.data.msg || "برجاء ادخال رقم موبيلي صحيح");
            setErrorMesg(true);
          }
        } catch (error) {
          setErrorMesgSubscribe("Failed to send OTP. Please try again.");
          setErrorMesg(true);
        } finally {
          setshowLoading(false);
        }
      }
    };
    
 /*  const Subscribe = async e => {
    e.preventDefault();
    if (activeData) {
      var phon_n = activeData.toString();      
      //o`peratorNetwork(phon_n);
    if(!showSms){
      const url = `http://api.playit.mobi/api/v2/3anet-saudi/send-otp?msisdn=${phon_n}&plan=d`
      setshowLoading(true);
      await axios.get(url)
        .then(res => {
          if (res.data.status === true) {
            if (res.data.user_id) {
              setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);
            }
            if (res.data.otpid) {
              setotpid(res.data.otpid)
            }
            setShowverif(true)
          }
          else {
            if (res.data.msg != null) {
              setErrorMesgSubscribe(res.data.msg);
              setErrorMesg(true);
            }
            else {
              setErrorMesgSubscribe(t("register.SUBSCRIBE_ERROR"));
              setErrorMesg(true);
            }
           // setShowverif(true)
          }
          setshowLoading(false);

        })
        .catch(function (error) {
          setErrorMesg(true)
          setShowverif(false)
        })
        .finally(function () {
         //console.log('complete');
        });
      }
      if(showSms){
        setShowverif(true);
      }

    }
  };
 */

  const verify = async e => {
    e.preventDefault();
    if (verifyCode) {
      var phon_n = activeData;
      setshowLoading(true);
      
      axios.get(`http://api.playit.mobi/api/v2/3anet-saudi/verify?msisdn=${phon_n}&pincode=${verifyCode}&plan=w`)
        .then(res => {
         //console.log(res);
          if (res.data.status == true) {
            setVerfiErrorMesg(false);
            tracker(phon_n);
            if (res.data.status) {
              
                history.push("/thankyouksa");
                //history.push("/");
              
                //setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);
              //history.push("/catch-Libyana?msisdn="+res.data.data.username);
            }else{
              if (res.data.message != null) {
                seterrorresverify(res.data.msg);;
                setVerfiErrorMesg(true);
      
              }
            }
           //  window.location.href = "/";
           //history.push("/catch-Libyana")
          } else {
            if (res.data.msg != null) {
              seterrorresverify(res.data.msg);;
              setVerfiErrorMesg(true);

            }
            else {
              // setErrorMesgSubscribe(t("register.SUBSCRIBE_ERROR"));
              seterrorresverify(t("register.VERIFY_ERROR"));
              setVerfiErrorMesg(true);


            }
            // setVerfiErrorMesg(true);
            // seterrorresverify(res.data.msg);
          }
          setshowLoading(false);

        })
        .catch(function (error) {
         //console.log(error);
          // setVerfiErrorMesg(true);
          // seterrorresverify(res.data.msg);

        })
        .finally(function () {

         //console.log('complete');
        });
    }
    if(showSms){
      window.location.href = 'sms:2231;?&body=1';
    }
  };

  const plansOptions = plans.map((c, k) => {
    return (
      <option key={`plan-${k}`} value={c.plan}>
        {c.name}
      </option>
    );
  });




  return (
    <Ksa3AnetSignupPageDisplay className="">
      {alert && (
        <FloatingAlert type={"danger"}>
          In order to signup please turn your mobile data.
        </FloatingAlert>
      )}

      {errorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{errorMesgSubscribe}</Trans>
        </FloatingAlert>
      ) : null}

      {verfiErrorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{errorresverify}</Trans>
        </FloatingAlert>
      ) : null}

      <div className='operatorSignup'>
        <div className="formsLayout formsLayoutjawal">

          <div className='header header_gamezone'>
            <Link to="/">
              <div className='logo logo gamezoneLogo' />
            </Link>
          </div>
          
          {!showverif ?
            <div className="links-Section links-Sectionjawwalgamezorid">

              {/* <h3 style={{ paddingBottom: '1px' }} className="gamezoridcontent" id="gamezoridcon">
                <Trans>Your content is ready!</Trans>
              </h3> */}

               <p style={{ fontSize: '12px' }} ><Trans>Subscribe to Gamezroid Portal and Play Games </Trans></p>
             
              <div className="play"></div>
{/* onSubmit={(e) => {  e.preventDefault(); Subscribe(e); }} */}
      <form name="formsubmit" onSubmit={(e) => {  e.preventDefault(); }}>
              <input
                type="text"
                className="input gamezoneInput gamezonekeyph"
                value="+966"
                disabled={true}
              />
{/* {t("Enter_your_phone_number")} */}
              <input
                type="text"
                className="input gamezoneInput gamezonenumph"
                placeholder="XX XXX XX XX"
                name="phone_number"
                disabled={checkPhone == true}
                onChange={(e) => operatorNetwork(e.target.value.replace(/[^0-9]/g, ""))}

              />
              <br/><br/><br/>
              <button
                id="signupbut"
                className="btn" 
                //disabled={activeData ? "" : "disabled"}
                type="submit"
                onClick={(e) => Subscribe(e)}

              >
                {t("Subscribe")}
                
              </button>
      </form>


              <div className="row text-center" >
                <div className="col-xl-4 col-lg-4 col-4 px-5">
                  <div className="games-info jawwalgames-info">
                    <span>98%</span><img src="/assets/images/subscribegamezriod/like.png" alt="img" className="iconsimg" />
                    <p><Trans>evaluation</Trans></p>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-4 px-5">
                  <div className="games-info jawwalgames-info">
                    <span>1000+</span><img src="/assets/images/subscribegamezriod/Repeat.png" alt="img" className="iconsimg" />
                    <p><Trans>game</Trans></p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-4 px-5">
                  <div className="games-info jawwalgames-info">
                    <span>500k+</span><img src="/assets/images/subscribegamezriod/group.png" alt="img" className="iconsimg" />
                    <p><Trans>user</Trans></p>
                  </div>
                </div>
              </div>


              <div className="footer jawwalgamezoridFooter zainiqFooter">
                {!showverif ? "" : null}
                <div className="ncellTos jawwalgamezoridncellTos">
                  {/* <h3><Trans>terms_and_conditions</Trans></h3> */}
                  <p style={{lineHeight:"1.2",margin:"0 0 5px 0"}}><Trans>3anet-saudi_tandc1</Trans></p>
                  {/* <p><Trans>zainiq-condtion-gamezone_tandc2</Trans></p> */}
                  {/* <p style={{lineHeight:"1.2",margin:"0 0 5px 0"}}><Trans>trendy-condtion_tandc3</Trans></p> */}
                  <p style={{lineHeight:"1.2",margin:"0 0 5px 0"}}><Trans>3anet-saudi_tandc2</Trans></p>
                  {/* <p style={{lineHeight:"2px"}}><Trans>libyana-condtion_tandc5</Trans></p> */}
                  <p style={{lineHeight:"1.2",margin:"0 0 5px 0"}}><Trans>3anet-saudi_tandc3</Trans></p>
                  {/* <p> <Trans>JAWWALGameSUBSCRIBE_TAX</Trans> </p>
                  <p> <Trans>JAWWALGamSUBSCRIBEUNSUBSCRIBE</Trans> </p> */}
                </div>
              </div>
            </div>

            : null}

          {showverif ?
            <div className="links-Section links-Sectionjawwalgamezorid">

              
              {/* verfiySection */}
                            <div className="play"></div>
              {showSms ? <p><Trans>Click to Subscribe</Trans></p> : 
              <p><Trans>verifycodeJawwalGameszorid</Trans></p>
              }
              {showSms ? null : <input
                type="text"
                className="input"
                value={verifyCode}
                placeholder={t("Enter_verifycode")}
                onChange={(e) => setVerifyCode(e.target.value.replace(/[^0-9]/g, ""))}
              />
              }
              <br></br>
             
              <button
                className="btn"
                disabled={(verifyCode || showSms) ? "" : "disabled"}
                onClick={(e) => {
                  verify(e);
                }} >
                {showSms ? t("Subscribe") : t("Subscribe")}
              </button>


              
              <div className="footer jawwalgamezoridFooter zainiqFooter">
                {!showverif ? "" : null}
                <div className="ncellTos jawwalgamezoridncellTos">
                  {/* <h3><Trans>terms_and_conditions</Trans></h3> */}
                  <p style={{lineHeight:"1.2",margin:"0 0 5px 0"}}><Trans>3anet-saudi_tandc1</Trans></p>
                  {/* <p><Trans>zainiq-condtion-gamezone_tandc2</Trans></p> */}
                  {/* <p style={{lineHeight:"1.2",margin:"0 0 5px 0"}}><Trans>trendy-condtion_tandc3</Trans></p> */}
                  <p style={{lineHeight:"1.2",margin:"0 0 5px 0"}}><Trans>3anet-saudi_tandc2</Trans></p>
                  {/* <p style={{lineHeight:"2px"}}><Trans>libyana-condtion_tandc5</Trans></p> */}
                  <p style={{lineHeight:"1.2",margin:"0 0 5px 0"}}><Trans>3anet-saudi_tandc3</Trans></p>
                  {/* <p> <Trans>JAWWALGameSUBSCRIBE_TAX</Trans> </p>
                  <p> <Trans>JAWWALGamSUBSCRIBEUNSUBSCRIBE</Trans> </p> */}
                </div>
              </div>

            </div> : null
          }


          {showLoading ?
            <div className="load">
              <div className="spinerC">
                <div className="spinner"></div>
              </div>
            </div>
            : null}




        </div>
      </div>

      {/* <div className='operatorSignup'>
        <div className="formsLayout">

          <div className='header'>
            <Link to="/">
              <div className='logo' />
            </Link>
          </div>

          {!showverif ?

            <div className="center">
              <form>
                <div>
                   <select

                    className="select"
                    defaultValue={signupPlan}
                    onChange={e => setSignupPlan(e.target.value)}
                  >

                    <Trans>{plansOptions}</Trans>
                  </select>
                

                  <input
                    type="number"
                    className="input"
                    placeholder={t("Enter_your_phone_number")}
                    value={activeData}
                    disabled={checkPhone == true}
                    onChange={e => setActiveData(e.target.value)}
                  />
                  <br></br>
                  <br></br> <br></br>
                  <button
                    className="btn"
                    disabled={activeData ? "" : "disabled"}
                    onClick={(e) => {
                      Subscribe(e);
                    }} >
                    {t("Subscribe")}
                  </button>

                </div>
              </form>
            </div>

            : null}




          {showverif ?
            <div className="verfiySection">
              <p><Trans>verifycodeSub</Trans></p>
              <input
                type="number"
                className="input"
                value={verifyCode}
                placeholder={t("Enter_verifycode")}
                onChange={e => setVerifyCode(e.target.value)}
              />
              <br></br>
              <br></br> <br></br>
              <button
                className="btn"
                disabled={verifyCode ? "" : "disabled"}
                onClick={(e) => {
                  verify(e);
                }} >
                {t("Verify")}
              </button>

            </div> : null
          }


          <div className="footer">
           
            <div className="ncellTos">

            </div>
          </div>

        </div>
      </div> */}

    </Ksa3AnetSignupPageDisplay>
  );
}
export default withTranslation()(Ksa3AnetSignupPage)

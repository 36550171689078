import styled from "styled-components";
const SingleGameDisplay = styled.div`
    ${'' /* background-color: #313a54; */}
    ${'' /* border-radius: 20px; */}
    margin-right: var(--gameMargin);
    margin-bottom: var(--gameMargin);
    margin-top: var(--gameMarginTop);
  a:first-child {
    margin : 10px !important;
  
  }
  > a {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    ${'' /* margin: 20px 0 20px 20px; */}
    text-decoration: none;
    color: var(--gamename);
  }
  .imgloader {
    ${'' /* border-radius: var(--imgBorder)!important; */}
    ${'' /* border: var(--gameBorderB); */}
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    width: 75px;
    height: 75px;
    ${'' /* margin-bottom: 10px; */}
    border-radius: 20px;
  }
  .onFail {
    width: 75px;
  }

  @media (min-width: 997px) {
    
    .imgloader {
      max-width : 177px
      width: auto;
      height: 175px;
      ${'' /* margin-right: 20px; */}
    }
    .hosUpP {
      font-size: 21px;
      width: 180px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      direction: ltr !important;
  }
  }
`;
export default SingleGameDisplay;

export const Thumb = styled.div`
  border: 1px solid #fff;
  border-radius: 12px;
  width: 75px;
  height: 75px;
  margin-bottom: 10px;
  background: url('${p => p.img}') center center no-repeat;
  background-size: cover;
`;

export const Title = styled.div`
  font-size: 16px;
 
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;


export const SingleGameContainer = styled.div`
  position: relative;
  overflow: hidden;
`;

export const ImgContainer = styled.div`
  position: relative;
  display: inline-block;

  &:hover div {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const GameNameOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  text-align: center;
  padding: 10px;
  opacity: 0;
  transform: translateY(30%);
  transition: opacity 0.3s ease, transform 0.3s ease;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 100%;
`;





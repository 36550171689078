import React, { useEffect, useRef, useState } from 'react'
// import Sun from '../../assets/icons/sun.svg'
// import Search from '../../assets/icons/search.svg'
import Avatar from "../../assets/icons/profile/lg-anonymous.svg";
import { StyledAuth, StyledAvatar, StyledDropdown, StyledDropdownItem, StyledHeader, StyledIcon, StyledLogo, StyledLogoutBtn, StyledProfile, StyledSearch } from './DesignNavbar.styles'
import Search from '../../assets/icons/Search';
import envo from "../../environments/environmentProd";
import TranslateIcon from '../../assets/icons/TranslateIcon';
import { Link } from "react-router-dom";
import { Trans, getI18n } from 'react-i18next';
import { client } from "../../auth/Client";
import { getLoginData } from '../../helpers/helpers';
import AuthContext from '../../ContextApi/AuthContext';
import { useContext } from 'react';
const apiUrl = envo.apiUrl;

const DesignNavbar = (props) => {
    const authContext = useContext(AuthContext);
    const [isLogged, setIsLogged] = useState(false);
    const [userInfo, setuserInfo] = useState({
      name: "",
      phone: "",
      email: "",
      userPhoto: localStorage.getItem("userPhoto") || '',
      userName: ''
    });
    const [phoneCancel, setphoneCancel] = useState();
    const [isScrolled, setIsScrolled] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
    async function InfoData() {
        const url = `${apiUrl}/checkAccessToken/${localStorage.getItem("token")}`;
        const respons = await fetch(url);
        const userData = await respons.json();
        // setNameCancel(userData.user_name);
        // setEmailCancel(userData.email);
        if (userData && userData.msisdn) {
        let phoneNumber = userData.msisdn;
        if (phoneNumber.startsWith("999")) {
            phoneNumber = phoneNumber.slice(3);
        }
        setphoneCancel(phoneNumber);
        }
        setuserInfo ({
        name: userData.user_name,
        email: userData.email || '',
        userPhoto: localStorage.getItem("userPhoto"),
        phone: userData.msisdn
        });
    }
    InfoData()
    checkAuth();
    }, []);

    async function checkAuth() {
       //console.log("checkAuth");
        const authorized = await client.auth();
       //console.log("auth", authorized);
        if (authorized) {
          const data = getLoginData();
          setuserInfo({
            ...userInfo,
            userName: data.userName,
            userPhoto: data.userPhoto
          })
          return setIsLogged(true);
        }
        setuserInfo({
            email: '',
            name: '',
            phone: '',
            userName: '',
            userPhoto: ''
        })
        return setIsLogged(false);
      }

    useEffect(() => {
      const handleScroll = () => {
        setIsScrolled(window.scrollY > 50);
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

     const toggleDropdown = () => setIsDropdownOpen((prev) => !prev);

     useClickOutside(dropdownRef, () => setIsDropdownOpen(false));

  return (
    <StyledHeader aria-label="navbar" scrolled={isScrolled}>
      <StyledLogo>
        <Link
          to={props.isNav === "brain-games" ? "/brain-games" : "/action-games"}
        >
          {props.isNav === "brain-games" ? "BRAIN-X" : "YALLA ACTION"}
        </Link>
      </StyledLogo>
      <StyledSearch>
        <input type="text" placeholder="Search" />
        <Search />
      </StyledSearch>
      <StyledAuth>
        <StyledIcon>
          <Link
            to={
              props.isNav === "brain-games"
                ? "/brain-games/change-language"
                : "/action-games/change-language"
            }
          >
            <TranslateIcon />
          </Link>
        </StyledIcon>
        {authContext.isAuthenticated ? (
          <StyledProfile ref={dropdownRef} onClick={toggleDropdown}>
            {/* <Link to="/brain-games/profile"> */}
            <StyledAvatar>
              <img
                src={
                  authContext.user.avatar !== "null" &&
                  authContext.user.avatar !== ""
                    ? authContext.user.avatar
                    : Avatar
                }
                alt="avatar"
              />
            </StyledAvatar>
            <p>
              {authContext.user.username
                ? authContext.user.username
                : phoneCancel
                ? phoneCancel
                : "N/A"}
            </p>
            {isDropdownOpen && (
              <StyledDropdown language={getI18n().language}>
                <StyledDropdownItem>
                  <Link
                    to={
                      props.isNav === "brain-games"
                        ? "/brain-games/profile"
                        : "/action-games/profile"
                    }
                  >
                    <Trans>side_nav.profile</Trans>
                  </Link>
                </StyledDropdownItem>
                <StyledDropdownItem>
                  <Link
                    to={
                      props.isNav === "brain-games"
                        ? "/brain-games/my-games"
                        : "/action-games/my-games"
                    }
                  >
                    <Trans>my_games</Trans>
                  </Link>
                </StyledDropdownItem>
                <StyledDropdownItem>
                  <Link
                    to={
                      props.isNav === "brain-games"
                        ? "/brain-games/logout"
                        : "/action-games/logout"
                    }
                  >
                    <Trans>side_nav.logout</Trans>
                  </Link>
                </StyledDropdownItem>
                <StyledDropdownItem>
                  <Link
                    to={
                      props.isNav === "brain-games"
                        ? "/brain-games/unsubscribe"
                        : "/action-games/unsubscribe"
                    }
                  >
                    <Trans>unsubscribe</Trans>
                  </Link>
                </StyledDropdownItem>
              </StyledDropdown>
            )}
          </StyledProfile>
        ) : (
          <div>
            <StyledLogoutBtn
              to={
                props.isNav === "brain-games"
                  ? "/brain-games/login"
                  : "/action-games/login"
              }
            >
              login
            </StyledLogoutBtn>
          </div>
        )}
      </StyledAuth>
    </StyledHeader>
  );
}

export default DesignNavbar

export const useClickOutside = (ref, onClose) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClose]);
};

import axios from "axios";
import React, { useState, state } from "react";
import { Trans, withTranslation } from "react-i18next";
import { client } from "../../../../../auth/Client";
import { connect } from "react-redux";
import envo from "../../../../../environments/environmentProd";
import { Img } from "../GameDetailsPage.styled";

const apiUrl = envo.apiUrl;
const selectedLang = localStorage.i18nextLng;

const urlcompetition = window.location.hostname.includes("competition");
//const urlcompetition = window.location.hostname.includes("local");

const screenfull = require("screenfull");
function toggleFullScreen() {
  if (screenfull.isEnabled) {
    screenfull.toggle();
  }
}

function GameDetails({
  game,
  setConfirmState,
  setPlayState,
  history,
  increase,
  decrease,
}) {
  //console.log("gameone" , game)

  const [favState, setFavState] = useState();
  const [likes_counter, setlikes_counter] = useState(game.likes_count);
  const [descClass, setCescClass] = useState("descLess");
  const [descContent, setDescContent] = useState(true);

  /* SET VALUE ON GAMES COUNT */
  const options = {
    headers: { AUTHORIZATION: `Bearer ${localStorage.getItem("token")}` },
  };

  axios
    .get(`http://www.api.playit.mobi/api/v2/playit/stats`, options)
    .then((res) => {
      //console.log(res.data.data.myGamesCount)
    })
    .catch((error) => console.log(error));

  async function addFavourite(e, gameId) {
    localStorage.setItem("gameURL", history.location["pathname"]);
    e.preventDefault();

    const options = {
      headers: { AUTHORIZATION: `Bearer ${localStorage.getItem("token")}` },
    };
    const params = {
      id: gameId,
    };
    try {
      const res = await axios.post(
        `${apiUrl}/playit/game/favourite`,
        params,
        options
      );
      if (!res.data) return false;
      const data = res.data;
      if (data.status !== true) {
        return false;
      }
      game.isFav = false;
      if (data.status === true) {
        //TODO: Add floating alert with success message

        if (data.action === "add") {
          setFavState(true);
          increase();
          setlikes_counter(likes_counter + 1);
        } else {
          decrease();
          setFavState(false);
          game.isFav = false;
          //setlikes_counter(likes_counter  - 1)
        }

        //console.log(game.isFav, favState)()
      }
    } catch (err) {
      if (err.response && err.response.status !== 200) {
        //TODO: Add floating alert with error message
        history.push("/action-games/login");
      }
      return false;
    }
  }

  const checkAuth = async () => {
    if (await client.auth()) {
      if (game.game_type === "easy") {
        setPlayState(true);
        history.push("/frame/" + game._id);
        toggleFullScreen();
      } else {
        setConfirmState(true);
      }
    } else if (!(await client.auth()) && urlcompetition) {
      localStorage.setItem("gameURL", history.location["pathname"]);
      history.push("/action-games/signup");

      /* if (game.game_type === "easy") { 
        setPlayState(true); history.push('/frame/' + game._id) }
         else { 
          setConfirmState(true);
          localStorage.setItem("gameURL", history.location['pathname']);
          history.push('/signup'); }*/
      //if (game.game_type === "easy") { setPlayState(true); history.push('/frame/' + game._id) } else { setConfirmState(true); }
    } else {
      //console.log(history.location['pathname']);
      localStorage.setItem("gameURL", history.location["pathname"]);
      history.push("/action-games/login");
    }
  };

  const changeClass = async () => {
    if (descClass == "desc") {
      setCescClass("descLess");
      setDescContent(true);
    } else {
      setCescClass("desc");
      setDescContent(false);
    }
  };

  return (
    <>
      <div className="gameHeader">
        <div className="gameData">
          <Img src={game.game_name} img={encodeURI(game.game_thumb)}></Img>
          <div className="titleDetails">
            <div className="title">
              {selectedLang === "ar"
                ? game.hasOwnProperty("game_name_ar") &&
                  game.game_name_ar !== "null" &&
                  game.game_name_ar !== "" &&
                  game.game_name_ar !== null &&
                  game.game_name_ar !== "undefined" &&
                  game.game_name_ar !== "undefine"
                  ? game.game_name_ar
                  : game.game_name
                : game.game_name}
            </div>

            <div className="details">
              <div>By {game.game_dev} | </div>
              <div>
                <Trans> {game.game_cat} </Trans>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="gameBody">
        <div className={descClass}>
          {selectedLang === "en"
            ? game.hasOwnProperty("game_desc") &&
              game.game_desc !== null &&
              game.game_desc !== "undefined" &&
              game.game_desc !== "" &&
              game.game_desc !== "null"
              ? game.game_desc
              : game.game_desc
            : null}

          {selectedLang === "pt"
            ? game.hasOwnProperty("game_desc_pr") &&
              game.game_desc_pr !== null &&
              game.game_desc_pr !== "undefined" &&
              game.game_desc_pr !== "undefine" &&
              game.game_desc_pr !== "" &&
              game.game_desc_pr !== "null"
              ? game.game_desc_pr
              : game.game_desc
            : null}

          {selectedLang === "ar"
            ? game.hasOwnProperty("game_desc_ar") &&
              game.game_desc_ar !== null &&
              game.game_desc_ar !== "undefined" &&
              game.game_desc_ar !== "undefine" &&
              game.game_desc_ar !== "null" &&
              game.game_desc_ar !== ""
              ? game.game_desc_ar
              : game.game_desc
            : null}
        </div>

        <p
          className="seeP"
          onClick={() => {
            changeClass();
          }}
        >
          ...{" "}
          {descContent ? (
            <Trans>gameDetails.seeMore</Trans>
          ) : (
            <Trans>gameDetails.seeLess</Trans>
          )}
        </p>
        <div className="thumb_images">
          <img src={game.game_photo}></img>
        </div>

        <div className="images"></div>
        <div className="gameStatistics">
          <div className="likes">
            <div>{likes_counter}</div>
            <div>
              <Trans>gameDetails.game_likes</Trans>
            </div>
          </div>
          <div className="hours">
            <div>{game.play_count}</div>
            <div>
              <Trans>gameDetails.total_hours</Trans>
            </div>
          </div>
          {game.hasOwnProperty("download_counter") ? (
            <div className="users">
              <div>{game.download_counter}</div>
              <div>
                <Trans>gameDetails.total_users</Trans>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="gameFooter">
        <div
          className="playBtn"
          onClick={() => {
            checkAuth();
          }}
        >
          <i className="icon" />
          <button className="btn">
            <Trans>gameDetails.{game.btn_value}</Trans>
          </button>
        </div>

        <div
          className={"favIcon " + (game.isFav || favState ? "isFav" : "notFav")}
          onClick={(e) => addFavourite(e, game._id)}
        ></div>
      </div>
    </>
  );
}

/* Start Update State Use REDUX */
function mapStateToProps(state) {
  return { gamesCo: state.count };
}

function mapDispatchToProps(dispatch) {
  return {
    increase: () => dispatch({ type: "INCREASE" }),
    decrease: () => dispatch({ type: "DECREASE" }),
  };
}

/* End Update State Use REDUX */
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(GameDetails));

// export default withTranslation()(GameDetails);

import React, { useEffect, useState } from "react";


import { Link } from "react-router-dom";
import axios from "axios";
import FloatingAlert from "../../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../../helpers/bgSliders";
import "./SignupPage.scss";
import LibyanaSignupPageDisplay from "./SignupPage.styled";
import { setLoginData } from "../../../helpers/helpers";
import { Trans, withTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import { setAgencyData, saveTracker } from "../../../helpers/agencyTracker";
import queryString from "query-string";
import * as localLang from "../../../i18n/i18n";
const randomBg = bgSliders.getRandomBg();

function LibyanaSignupPage({ t }) {
  const [activeData, setActiveData] = useState("");
  const [transactionidentify,setTransactionidentify] = useState("");
  const [checkPhone, setCheckPhone] = useState(false);

  const [showverif, setShowverif] = useState(false);
  const [verifyCode, setVerifyCode] = useState("");
  const [errorMesg, setErrorMesg] = useState(false);
  const [verfiErrorMesg, setVerfiErrorMesg] = useState(false);
  const [errorresverify, seterrorresverify] = useState("");

  const [errorMesgSubscribe, setErrorMesgSubscribe] = useState("");
  const [signupPlan, setSignupPlan] = useState("d");
  const [otpid, setotpid] = useState("");
  const [showLoading, setshowLoading] = useState(false);



  const history = useHistory();
  const [alert, setAlert] = useState(false);
     let currentURL = window.location.href;
    let BaseURL="http://api.playit.mobi/api/"

  const plans = [
    {
      plan: "d",
      name: "Daily"
    },
    {
      plan: "w",
      name: "Weekly"
    }
  ];
  
  function gtag_report_conversion() {
    let tracker_value = localStorage.getItem('tracker');
      if(localStorage.getItem(tracker_value) != '1'){
        window.gtag('event', 'conversion', {
          'send_to': 'AW-619421651/C_ScCIHe_qcZENO_rqcC'
      });
      localStorage.setItem(tracker_value,'1')
    }
    return false;
  }

  useEffect(() => {

    if(currentURL.startsWith('https://')){
      BaseURL="https://apissl.playit.mobi/api/"
    }
    const params = queryString.parse(window.location.search);
    if (!params.lang) {
      localStorage.setItem("i18nextLng", 'ar');
    } else {
      localStorage.setItem("i18nextLng", params.lang);
    }
    
    if (document.readyState === "complete") {
      headerSignup();
    } else {
      window.addEventListener('load', () => {
        
        headerSignup();       
      });
    }     
    const urlsearch = window.location.search;
    if (urlsearch !== "") {
      setAgencyData(urlsearch);
    }
  }, []);


  
  async function headerSignup(){
 
   if(currentURL.startsWith('https://')){
    BaseURL="https://apissl.playit.mobi/api/"
  }

    var url =BaseURL+`v2/connex-libya/get-protected-script?targeted_element=%23cta_button`;
    axios.get(url)
      .then(res => {
        if (res.data.status == true) {
      

          const addScript = document.createElement("script");
            addScript.innerHTML = res.data.dcbprotect;
            document.body.appendChild(addScript); 
            
            setTransactionidentify(res.data.transaction_identify);
            setshowLoading(false);
            
            var event = new Event("DCBProtectRun");
            document.dispatchEvent(event);

        }

        else {
          setErrorMesg(true);
          setErrorMesgSubscribe(res.data.message)
        }

        setshowLoading(false);


      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(function () {
      });
  }

    // save tracker
    const tracker = async (GMSISDN) => {
      var datag = {};
      if (localStorage.getItem('tracker')) {
        var op_id = 'CcoIV1eQFA';
        if (GMSISDN.startsWith("21891") || GMSISDN.startsWith("21893")  || GMSISDN.startsWith("91") || GMSISDN.startsWith("93")) {
          op_id = 'qiL2bqfIL3';
        }
    
        datag = {
          key: 'parameter',
          company: localStorage.getItem("trackerCompany"),
          value: localStorage.getItem('tracker'),
          identifierKey: 'id',
          identifierValue: op_id,
          phoneNum: GMSISDN
        };
  
      }
      await saveTracker(datag);
  
    }

  const Subscribe = async e => {
    setActiveData(e.target.phone_number.value);
    const phone_number_val = e.target.phone_number.value;
    e.preventDefault();

    if (phone_number_val) {
      var phon_n = phone_number_val.toString();
     
      if(currentURL.startsWith('https://')){
        BaseURL="https://apissl.playit.mobi/api/"
      }
       let  url = BaseURL+`v2/connex-libya/send-otp-login`

      
      const options = {
        msisdn:phon_n,
        transaction_identify:transactionidentify,
      };
      
      setshowLoading(true);
      await axios.post(url, options)
        .then(res => {
          if (res.data.status === true) {
            if (res.data.user_id) {
              setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);
            }
            if (res.data.otpid) {
              setotpid(res.data.otpid)
            }
            setShowverif(true)
          }
          else {
            if (res.data.msg != null) {
              setErrorMesgSubscribe(res.data.msg);
              setErrorMesg(true);

            }
            else {
              setErrorMesgSubscribe(t("register.SUBSCRIBE_ERROR"));
              setErrorMesg(true);


            }


          }
          setshowLoading(false);

        })
        .catch(function (error) {
          setErrorMesg(true)
          setShowverif(false)
        })
        .finally(function () {
        });


    }
  };


  const verify = async e => {
    e.preventDefault();
    if (verifyCode) {
      var phon_n = activeData;
      setshowLoading(true);
      if(currentURL.startsWith('https://')){
        BaseURL="https://apissl.playit.mobi/api/"
      }
  
      let url =BaseURL+"v2/connex-libya/login-confirm"
     
      axios.get(url+ `?msisdn=${phon_n}&pincode=${verifyCode}&plan=w`)
        .then(res => {
          if (res.data.status == true) {
            setVerfiErrorMesg(false);
            if (res.data.data.user_id) {
              
              if((res.data.data.subscription_status)){
                tracker(phon_n);
                gtag_report_conversion();

               window.location.href =`https://libya.playit.mobi/catch-Libyana?msisdn=${res.data.data.username}`;
              }else{
                seterrorresverify(res.data.data.message);;
                setVerfiErrorMesg(true);
              }
             
            }else{
              if (res.data.message != null) {
                seterrorresverify(res.data.msg);;
                setVerfiErrorMesg(true);
      
              }
            }
         
          } else {
            if (res.data.msg != null) {
              seterrorresverify(res.data.msg);;
              setVerfiErrorMesg(true);

            }
            else {
              seterrorresverify(t("register.VERIFY_ERROR"));
              setVerfiErrorMesg(true);


            }
         
          }
          setshowLoading(false);

        })
        .catch(function (error) {

        })
        .finally(function () {

        });
    }
  };

  const plansOptions = plans.map((c, k) => {
    return (
      <option key={`plan-${k}`} value={c.plan}>
        {c.name}
      </option>
    );
  });




  return (
    <LibyanaSignupPageDisplay >
     
      {alert && (
        <FloatingAlert type={"danger"}>
          In order to signup please turn your mobile data.
        </FloatingAlert>
      )}

      {errorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{errorMesgSubscribe}</Trans>
        </FloatingAlert>
      ) : null}

      {verfiErrorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{errorresverify}</Trans>
        </FloatingAlert>
      ) : null}

      <div className='operatorSignup'>
        <div className="formsLayout formsLayoutjawal">

          <div className='header header_gamezone'>
            <Link to="/">
              <div className='logo logo gamezoneLogo cptpl_logo'  alt="service logo" />
            </Link>
          </div>
          {!showverif ?
            <div className="links-Section links-Sectionjawwalgamezorid">

            
              <div className="play"></div>
              
<div className="main_span cptpl_service">
 <p > <Trans>Playit offers more than 500 games for game lovers</Trans></p>
  </div>

             
<form name="formsubmit" onSubmit={(e) => {  e.preventDefault(); Subscribe(e); }}>

              <input
                type="text"
                className="input gamezoneInput gamezonekeyph"
                value="218"
                disabled={true}
              />
              <input
                type="text"
                className="input gamezoneInput gamezonenumph"
                placeholder="XX XXX XX XX"
                name="phone_number"
                disabled={checkPhone == true}
              />
              <br/><br/><br/>
              <button
                 
                 id="cta_button"
                className="btn reg_but cptpl_subscribe" 
                type="submit"
              >
                {t("Continue to subscribe")}
              </button>
</form>


              <div className="row text-center" >
                <div className="col-xl-4 col-lg-4 col-4 px-5">
                  <div className="games-info jawwalgames-info">
                    <span>98%</span><img src="/assets/images/subscribegamezriod/like.png" alt="img" className="iconsimg" />
                    <p><Trans>evaluation</Trans></p>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-4 px-5 ">
                  <div className="games-info jawwalgames-info">
                    <span>1000+</span><img src="/assets/images/subscribegamezriod/Repeat.png" alt="img" className="iconsimg" />
                    <p><Trans>game</Trans></p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-4 px-5">
                  <div className="games-info jawwalgames-info">
                    <span>500k+</span><img src="/assets/images/subscribegamezriod/group.png" alt="img" className="iconsimg" />
                    <p><Trans>user</Trans></p>
                  </div>
                </div>
              </div>


              <div className="footer jawwalgamezoridFooter zainiqFooter">
                {!showverif ? "" : null}
                <div className="ncellTos jawwalgamezoridncellTos ">
                             <p className="price_title cptpl_price" style={{lineHeight:"1.2",margin:"0 0 5px 0"}}><Trans>libyana-condtion_tandc4</Trans></p>
                   <p className="cptpl_terms" style={{lineHeight:"1.2",margin:"0 0 5px 0"}}><Trans>libyana-condtion_term</Trans></p>

               
                </div>
              </div>
            </div>

            : null}

          {showverif ?
            <div className="verfiySection">
              <p><Trans>verifycodeJawwalGameszorid</Trans></p>
              <input
              
                type="text"
                className="input"
                value={verifyCode}
                placeholder={t("Enter_verifycode")}
                onChange={(e) => setVerifyCode(e.target.value.replace(/[^0-9]/g, ""))}
              />
              <br></br>
              <br></br> <br></br>
              <button
                className="btn"
                disabled={verifyCode ? "" : "disabled"}
                onClick={(e) => {
                  verify(e);
                }} >
                {t("Verify")}
              </button>

            </div> : null
          }

          {showLoading ?
            <div className="load">
              <div className="spinerC">
                <div className="spinner"></div>
              </div>
            </div>
            : null}




        </div>
      </div>

     

    </LibyanaSignupPageDisplay>
  );
}
export default withTranslation()(LibyanaSignupPage)

import React, { Component } from "react";
import AuthContext from "./AuthContext";

class AuthProvider extends Component {
  state = {
    isAuthenticated:
      localStorage.getItem("email") ? true : false,
    user: {
      email: localStorage.getItem("email") || "",
      avatar: localStorage.getItem("userPhoto") || "",
      username: localStorage.getItem("userName") || "",
      phone: localStorage.getItem("phoneNum") || "",
    },
  };

  login = (user) => {
    this.setState({
      isAuthenticated: true,
      user,
    });
  };

  logout = () => {
    this.setState({
      isAuthenticated: false,
      user: {
        email: "",
        avatar: "",
        username: "",
        phone: "",
      },
    });
  };

  render() {
    const { isAuthenticated, user } = this.state;
    const { children } = this.props;

    return (
      <AuthContext.Provider
        value={{
          isAuthenticated,
          user,
          login: this.login,
          logout: this.logout,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  }
}

export default AuthProvider;

import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { Navbar } from "../components";
import { client } from "./Client";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import DesignNavbar from "../components/Navbar/DesignNavbar";
import Footer from "../components/Footer/Footer";


export default function SafeRoute({
  hasNav = true,
  locked = false,
  component: Component,
  isNav = '',
  ...rest
}) {
  // auth numbers meaning
  // 0 = not authorized
  // 1 = checking
  // 2 = authorized
  const handle = useFullScreenHandle();

  const [authorized, setAuthorized] = useState(1);
  useEffect(() => {
    async function checkAuth() {
      const res = await client.auth();
      if (res === true) {
        return setAuthorized(2);
      }
     //console.log('authorized',authorized)
      return setAuthorized();
      // return setAuthorized(0);
    }
    checkAuth();
  }, authorized);

  // console.log(hasNav, isNav)

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {hasNav && <Navbar history={props.history}/>}
          {isNav && <DesignNavbar isNav={isNav}/>}
          {/* {isNav && <Navbar history={props.history} path={isNav} />} */}
          {locked ? (
            <>
              {authorized === 2 && <Component {...props} />}
              {authorized === 0 && <Redirect to="/login" />}
              {authorized === 1 && (
                <div>
                  <div className="spinerC">
                    <div className="spinner"></div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <Component {...props} />
          )}
          {isNav && <Footer isNav={isNav}/>}
        </>
      )}
    />
  );
}

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import FloatingAlert from "../../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../../helpers/bgSliders";
import "./SignupPage.styled";
import KoreqSignupPageDisplay from "./SignupPage.styled";
import { setLoginData } from "../../../helpers/helpers";
import { Trans, withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { setAgencyData, saveTracker } from "../../../helpers/agencyTracker";
import queryString from "query-string";



const randomBg = bgSliders.getRandomBg();
function KoreqSignupPage({ t }) {
  const [activeData, setActiveData] = useState("");
  const [checkPhone, setCheckPhone] = useState(false);

  const [showverif, setShowverif] = useState(false);
  const [verifyCode, setVerifyCode] = useState(false);
  const [errorMesg, setErrorMesg] = useState(false);
  const [verfiErrorMesg, setVerfiErrorMesg] = useState(false);
  const [errorresverify, seterrorresverify] = useState("");

  const [errorMesgSubscribe, setErrorMesgSubscribe] = useState("");
  const [signupPlan, setSignupPlan] = useState("d");
  const [otpid, setotpid] = useState("");
  const [showLoading, setshowLoading] = useState(false);
  const [transactionidentify,setTransactionidentify] = useState("");
  const [transactionid,setTransactionid] = useState("");


  const history = useHistory();
  const [alert, setAlert] = useState(false);
  const params = queryString.parse(window.location.search);
  const [btn, setbtn] = useState(false);


 
  const [randomString, setRandomString] = useState('');

  const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };
  const changeBtn = (e) => {
    setbtn(true);
  };
  useEffect(() => {
     const newRandomString = generateRandomString(10); 
  setRandomString(newRandomString);
    const fetchData = async () => {
      try {
        const res = await axios.get(`http://api.playit.mobi/api/v2/korek-mobimind/get-script`);
       
         if (res.data.success == true) {
           
           const addScript = document.createElement('script');
          
           addScript.innerHTML = res.data.script;
           document.head.appendChild(addScript);
           setshowLoading(false);
           var event = new Event('DCBProtectRun');
           document.dispatchEvent(event);
           localStorage.setItem("ti",res.data.AntiFrauduniqid);
           localStorage.setItem("clickId",res.data.clickID);
         }
         else {
           setErrorMesg(true);
           setErrorMesgSubscribe(res.data.message)
           
         }
  
         setshowLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
   
    fetchData();
  }, []);

 
  const redirect = async () => {
    let ti = localStorage.getItem("ti");
    try {
      const res = await axios.get(`http://api.playit.mobi/api/v2/korek-mobimind/get-redirect-url?ti=${ti} `);
     
       if (res.data.status == true) {
      //   console.log(res.data.url);
        window.location.href = res.data.url;
       }
       else {
         setErrorMesg(true);
         setErrorMesgSubscribe(res.data.msg)
         
       }

       setshowLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
 
 
 






  return (
    <KoreqSignupPageDisplay className="">
      {alert && (
        <FloatingAlert type={"danger"}>
          In order to signup please turn your mobile data.
        </FloatingAlert>
      )}

      {errorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{errorMesgSubscribe}</Trans>
        </FloatingAlert>
      ) : null}

      {verfiErrorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{errorresverify}</Trans>
        </FloatingAlert>
      ) : null}

      <div className='operatorSignup'>
        <div className="formsLayout formsLayoutjawal">

          <div className='header header_gamezone'>
            <Link to="/">
              <div className='logo logo gamezoneLogo' />
            </Link>

          </div>
          {!showverif ?
            <div className="links-Section links-Sectionjawwalgamezorid">

              <h3 style={{ paddingBottom: '1px' }} className="gamezoridcontent" id="gamezoridcon">
                <Trans>Your content is ready!</Trans>
              </h3>
              <div className="play"></div>

         <div className="">
 <p > <Trans>Gamez World offers more than 500 games for game lovers</Trans></p>
  </div>
            
            
            { !btn ?
             <button
              className="btn"
             
              onClick={(e) => { changeBtn(e) }}
            >
              {t("Subscribe")}
            </button> : 
            
            <button
              
              className=" AFsubmitbtn btn "
             id="AFsubmitbtn"
             
              onClick={(e) => { redirect(e) }}
            >
              {t("Verify Subscribtion")} 

          </button>
            }
              <div className="row text-center" >
                <div className="col-xl-4 col-lg-4 col-4 px-5">
                  <div className="games-info jawwalgames-info">
                    <span>98%</span><img src="/assets/images/subscribegamezriod/like.png" alt="img" className="iconsimg" />
                    <p><Trans>evaluation</Trans></p>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-4 px-5">
                  <div className="games-info jawwalgames-info">
                    <span>1000+</span><img src="/assets/images/subscribegamezriod/Repeat.png" alt="img" className="iconsimg" />
                    <p><Trans>games</Trans></p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-4 px-5">
                  <div className="games-info jawwalgames-info">
                    <span>500k+</span><img src="/assets/images/subscribegamezriod/group.png" alt="img" className="iconsimg" />
                    <p><Trans>users</Trans></p>
                  </div>
                </div>
              </div>


              <div className="footer jawwalgamezoridFooter zainiqFooter">
                {!showverif ? <div>

                </div> : null}
                <div className="ncellTos jawwalgamezoridncellTos">
                  <h3><Trans>terms_and_conditions</Trans></h3>
                  <p><Trans>koreq-tendy-condtion</Trans></p>
                 
                </div>
              </div>
            </div>

            : null}

        
          {showLoading ?
            <div className="load">
              <div className="spinerC">
                <div className="spinner"></div>
              </div>
            </div>
            : null}




        </div>
      </div>

      

    </KoreqSignupPageDisplay>
  );
}
export default withTranslation()(KoreqSignupPage)

import React from "react";

// Define the context with default values
const AuthContext = React.createContext({
  isAuthenticated: false,
  user: {
    email: '',
    avatar: '',
    username: '',
    phone: ''
  },
  login: (userData) => {},
  logout: () => {},
});

export default AuthContext;

export const barinList = [
  {
    url: "/brain-games",
    text: "Home",
  },
  {
    url: "/brain-games/profile",
    text: "side_nav.profile",
    logged: true,
  },
  {
    url: "/brain-games/my-games",
    text: "my_games",
    logged: true,
  },
  // {
  //   url: "/",
  //   text: "side_nav.my_coins",
  //   logged: true
  // },
  // {
  //   url: "/faqs",
  //   text: "side_nav.faq"
  // },
  {
    url: "/brain-games/tos",
    text: "terms_and_conditions",
  },
  // {
  //   url: "/rules",
  //   text: "side_nav.rules"
  // },

  // {
  //   url: "/videoPlay",
  //   text: "side_nav.videos_tutorials"
  // },
  //  {
  //      url: "/payment",
  //     text: "payment_methods",
  //     logged: true
  //    },
  {
    url: "/brain-games/change-language",
    text: "side_nav.language",
  },
  {
    url: "/brain-games/logout",
    text: "side_nav.logout",
    logged: true,
  },
  {
    url: "/brain-games/unsubscribe",
    text: "unsubscribe",
    logged: true,
  },
];

import styled from "styled-components";
const KoreqSignupPageDisplay = styled.div`
background: ${p => p.bgImg};
background-size: cover;
.formsLayoutjawal
{
  min-height: 114vh;
}
.gamezoneSignup
{
  background: rgb(238 90 17);
}
.header_gamezone
{
  margin-top: 5px!important;
  width: 100%;
  border-bottom: 2px solid #65378b;
}
.links-Section{
  position: absolute;
  //border: 1px solid red;

    top: 30%;
}
.links-Sectionjawwalgamezorid
{
  top: 9%!important;
  width: 85%;
  .play
  {

   background: var(--games_icon) center center no-repeat;
   height: 164px;
   background-size: var(--background-size)!important;
  }
  input
  {
    margin-top: 10px;
  }
  .gamezoneInput
  {
    background: white;
    color: black;
    padding: 1px 8px;
  }
  
}



.links-Section a {
  color:white
}
.links-Section button{
  margin-bottom: 15px;
  display:block;
}
.links-Sectionjawwalgamezorid button{
  display: block;
  margin: 30px auto;
  min-width: 190px;
}
.formsLayout {
  margin: 0 20px;
  .verfiySection
  {
    top: 26%!important;
  width: 61%;
  position: absolute;
  }
  .gamezoneLogo
  {
    max-width: 163px!important;
  }
  .inputs {
    select {
      width: 35%;
      height: 43px;
    }
    input {
      width: 50%;
    }
  }
    
  .footer{
    .ncellTos {
      font-size: 13px;
      margin-top: 20px;
      display:flex;
      flex-direction:column;
    }

  }
  .zainiqFooter
    {
      p
      {
        font-size : 14px;
      }
    }
}
    .btn:hover {
  color: white !important;
  background-color: #009edd !important;
}
`;	export default KoreqSignupPageDisplay;

import styled from "styled-components";
const SingleGameDisplay = styled.div`
    width: calc(100% / 6);
    background-color: #313a54;
    border-radius: 20px;
    margin-right: var(--gameMargin);
    margin-bottom: var(--gameMargin);
    margin-top: var(--gameMarginTop);
  a:first-child {
    margin : 10px !important;
  
  }
  > a {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    ${"" /* margin: 20px 0 20px 20px; */}
    text-decoration: none;
    color: var(--gamename);
  }
  .imgloader {
    ${"" /* border-radius: var(--imgBorder)!important; */}
    ${"" /* border: var(--gameBorderB); */}
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    width: 75px;
    height: 75px;
    margin-bottom: 10px;
  }
  .onFail {
    width: 75px;
  }

  @media (min-width: 997px) {

    
    
    .imgloader {
      max-width : 177px
      width: auto;
      height: 175px;
      ${"" /* margin-right: 20px; */}
    }
    .hosUpP {
      font-size: 21px;
      width: 180px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      direction: ltr !important;
  }
  }

  @media (max-width: 700px) {
    width: calc(100% / 5);
  }
`;
export default SingleGameDisplay;

export const Thumb = styled.div`
  border: 1px solid #fff;
  border-radius: 12px;
  width: 75px;
  height: 75px;
  margin-bottom: 10px;
  background: url('${p => p.img}') center center no-repeat;
  background-size: cover;
`;

export const Title = styled.div`
  font-size: 16px;
 
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;








import React from "react";

const Search = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.491"
      height="16.491"
      viewBox="0 0 16.491 16.491"
    >
      <defs>
        <style>
          {`
            .cls-1,
            .cls-2 {
              stroke: #fff;
              stroke-linecap: square;
              stroke-miterlimit: 10;
            }
            .cls-1 {
              fill: none;
            }
            .cls-2 {
              fill: rgba(0, 0, 0, 0);
            }
          `}
        </style>
      </defs>
      <g id="zoom-2" transform="translate(-0.5 -0.5)">
        <path
          id="Line_96"
          d="M4.076 4.076L0 0"
          className="cls-1"
          data-name="Line 96"
          transform="translate(12.208 12.208)"
        />
        <circle
          id="Ellipse_377"
          cx="6.55"
          cy="6.55"
          r="6.55"
          className="cls-2"
          data-name="Ellipse 377"
          transform="translate(1 1)"
        />
      </g>
    </svg>
  );
};

export default Search;

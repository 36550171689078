import styled from "styled-components";

export const StyledFooter = styled.footer`
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  padding: 30px;
  margin-top: 20px;
`;
export const StyledTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 50px;
  @media (max-width: 720px) {
    flex-direction: column;
  }
`;
export const StyledCol = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
  a {
    margin: 0;
    font-weight: 100;
    opacity: 0.7;
    color: white;
    cursor: pointer;
    :hover {
      color: white;
    }
  }
  .col-title {
    opacity: 1;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 800;
    margin-bottom: 5px;
  }
  @media (max-width: 720px) {
    align-items: center;
  }
`;
export const StyledBottom = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
  p {
    margin: 0;
  }
  @media (max-width: 720px) {
    flex-direction: column;
  }
`;

export const StyledIcons = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
  p {
    margin: 0;
  }
`;

export const StyledIcon = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 2px white solid;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (max-width: 720px) {
    width: 30px;
    height: 30px;
  }
`;
export const StyledFbIcon = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 2px #596beb solid;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #596beb;
  @media (max-width: 720px) {
    width: 30px;
    height: 30px;
  }
`;
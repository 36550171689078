import React from 'react'
import { StyledBottom, StyledCol, StyledFbIcon, StyledFooter, StyledIcon, StyledIcons, StyledTop } from './Footer.styles'
import Fb from '../../assets/icons/Fb'
import Twitter from './../../assets/icons/Twitter';
import Instagram from './../../assets/icons/Instagram';
import Github from './../../assets/icons/Github';
import { Link } from "react-router-dom";
import { StyledLogo } from '../Navbar/DesignNavbar.styles';
import { Trans } from 'react-i18next';

const Footer = (props) => {
  const urlGamezonekorek = window.location.href.includes("gamezones");
  return (
    <StyledFooter>
      <StyledTop>
        <StyledCol>
          <StyledLogo>
            <Link
            to={props.isNav === "brain-games" ? "/brain-games" : "/action-games"}
            >
            {props.isNav === "brain-games" ? "BRAIN-X" : "YALLA ACTION"}
            </Link>
        </StyledLogo>
        </StyledCol>
        {/* <StyledCol>
          <p className="col-title">company</p>
          <Link>company</Link>
          <Link>company</Link>
        </StyledCol>
        <StyledCol>
          <p className="col-title">help</p>
          <Link>company</Link>
          <Link>company</Link>
        </StyledCol> */}
        <StyledCol>
          <p className="col-title">resources</p>
          <Link to="/brain-games/tos">
            <Trans>terms_and_conditions</Trans>
          </Link>
          {urlGamezonekorek ? (
            <a href="http://gamezones.mobi/gamezones-privacypolicy.html">
              <Trans>Privacy_Policy</Trans>
            </a>
          ) : (
            <a href="http://playit.mobi/privacypolicy.html">
              <Trans>Privacy_Policy</Trans>
            </a>
          )}
        </StyledCol>
      </StyledTop>
      <StyledBottom>
        {/* <StyledIcons>
          <StyledFbIcon>
            <Fb />
          </StyledFbIcon>
          <StyledIcon>
            <Twitter />
          </StyledIcon>
          <StyledIcon>
            <Instagram />
          </StyledIcon>
          <StyledIcon>
           <Github />
          </StyledIcon>
        </StyledIcons> */}
        <p>© Copyright 2025, All Rights Reserved.</p>
      </StyledBottom>
    </StyledFooter>
  );
}

export default Footer
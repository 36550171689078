import React from 'react';
import { Trans, withTranslation } from "react-i18next";
import { Footer, Links } from "./ProfilePage.styled";
import { Component } from 'react';
import FloatingAlert from './../../components/FloatingAlert/FloatingAlert';
import axios from 'axios';
import { connect } from 'react-redux';
import envo from "../../environments/environmentProd";
const apiUrl = envo.apiUrl;

const gamezoneUrl = window.location.href.includes("ao.playit");
const algeriaorUrl = window.location.href.includes("dzo");


class ProfileFooter extends Component {
  constructor() {
    super();
    this.state = {
      changePass: false,
      current: "",
      newPass: "",
      confirmNew: "",
      errors: false,
      errorMsg: "",
      success: false,
      successMsg: "",
      subscribeDate: '',
      renewalDate: '',
      plan: '',
      PackageCode: '',
      SubscriptionDate: ''
    }
   //console.log(gamezoneUrl);

  }



  // get user data

  async componentDidMount() {
    if (gamezoneUrl) {
     //console.log("task")
      document.getElementById('linkUnsubscribe').setAttribute('class', 'linkUnsubscribe')

    }
    const url = `${apiUrl}/checkAccessToken/${localStorage.getItem("token")}`;
    const respons = await fetch(url);
    const userData = await respons.json();
    this.setState({ subscribeDate: userData.subscribeDate, renewalDate: userData.renewalDate, PackageCode: userData.PackageCode, SubscriptionDate: userData.SubscriptionDate, PackageCode: userData.PackageCode });
    //localStorage.setItem("phoneNum", userData.msisdn);

  }

  toggleView(e) {
    e.preventDefault();
    this.props.resitPasswordfun()
    this.setState({
      changePass: true
    })

  }


  async changePassword(e) {
    e.preventDefault();
    const { current, newPass, confirmNew } = this.state;
    const options = {
      headers: { AUTHORIZATION: `Bearer ${localStorage.getItem("token")}` }
    };
    const params = {
      current: current,
      password: newPass
    }
    try {
      if (!this.isMatched(newPass, confirmNew)) {
        this.setState({
          errors: true,
          errorMsg: "Confirm password not match new password"
        });
        return;
      }




      const res = await axios.post(`${apiUrl}/playit/password/update`, params, options);
      if (!res.data) return false;
      const data = res.data;
      if (data.status !== "success") {

        return false;
      }

      //TODO: After showing success message,route it view mode
      this.setState({
        success: true,
        successMsg: this.props.t("profile.password_changed")
      });
    } catch (err) {
      if (err.response && err.response.status !== 200) {
        this.setState({
          errors: true,
          errorMsg: err.response.data.message
        });
      }
      return false;
    }
  }



  onInputChange(name, value) {
    this.setState({
      [name]: value,
      errors: false
    });


  }

  isMatched(newPass, confirmNew) {
    return newPass === confirmNew ? true : false;
  }
  render() {

    const { t } = this.props;
    const { changePass, errors, errorMsg, success, successMsg } = this.state;
    //FIXME: When showing floating alert it appears above submit btn (style height issue)
    return (
      <>
        {changePass && this.props.resitPassword ? (
          <Footer>
            <form
              onSubmit={(e) => {
                this.changePassword(e);
              }}
            >
              <div className="form">
                <div className="iconInput">
                  <i className="icon lock" />
                  <input
                    type="password"
                    className="input"
                    placeholder={t("profile.current")}
                    onChange={(e) =>
                      this.onInputChange("current", e.target.value)
                    }
                  />
                </div>
                <div className="iconInput">
                  <i className="icon lock" />
                  <input
                    type="password"
                    className="input"
                    placeholder={t("profile.new")}
                    onChange={(e) =>
                      this.onInputChange("newPass", e.target.value)
                    }
                  />
                </div>
                <div className="iconInput">
                  <i className="icon lock" />
                  <input
                    type="password"
                    className="input"
                    placeholder={t("profile.confirm")}
                    onChange={(e) =>
                      this.onInputChange("confirmNew", e.target.value)
                    }
                  />
                </div>
              </div>

              <div className="normBtn">
                <button className="btn" type="submit">
                  <Trans>profile.change</Trans>
                </button>
              </div>
              <div className="normBtn">
                <button
                  className="btn cancel-btn"
                  onClick={() => {
                    this.setState({ changePass: false });
                  }}
                >
                  <Trans>profile.cancel</Trans>
                </button>
              </div>
            </form>
            {errors ? (
              <FloatingAlert type="error">{errorMsg}</FloatingAlert>
            ) : (
              ""
            )}
            {success ? (
              <FloatingAlert type="success">{successMsg}</FloatingAlert>
            ) : (
              ""
            )}
          </Footer>
        ) : (
          <>
            <Footer>
              <div className="subscribeDate">
                <div className="calanader"></div>
                <span className="title">
                  <Trans>profile.subscription_date</Trans>
                </span>

                {algeriaorUrl ? (
                  <span className="date">{this.state.SubscriptionDate}</span>
                ) : (
                  <span className="date">{this.state.subscribeDate}</span>
                )}
              </div>

              <div className="renewalDate">
                <div className="calanader"></div>
                <span className="title">
                  <Trans>profile.renewal_date</Trans>
                </span>
                <span className="date">{this.state.renewalDate}</span>
              </div>
            </Footer>
            {algeriaorUrl ? (
              <Footer>
                <div className="renewalDate">
                  <div className="calanader"></div>
                  <span className="title">
                    <Trans>profile.plan</Trans>
                  </span>

                  {this.state.PackageCode == "d" ||
                  this.state.PackageCode == "D" ? (
                    <span className="date">
                      <Trans>dailyalgeraplan</Trans>
                    </span>
                  ) : (
                    ""
                  )}
                  {this.state.PackageCode == "W" ||
                  this.state.PackageCode == "w" ? (
                    <span className="date">
                      <Trans>weeklyalgeraplan</Trans>
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </Footer>
            ) : null}
            <Links>
              {/* <a href=" " onClick={(e) => this.toggleView(e)}>
                  <Trans>profile.reset_my_password</Trans>
                </a> */}
              <a
                href={
                  window.location.pathname.includes("brain-games")
                    ? "/brain-games/unsubscribe"
                    : window.location.pathname.includes("action-games")
                    ? "/action-games/unsubscribe"
                    : "/unsubscribe"
                }
                id="linkUnsubscribe"
              >
                <Trans>unsubscribe</Trans>
              </a>
            </Links>
          </>
        )}
      </>
    );
  }
}

/* Start Update State Use REDUX */
function mapStateToProps(state) {
  return {
    resitPassword: state.resitPassword

  }
}

function mapDispatchToProps(dispatch) {
  return {
    editeInfoFun: () => dispatch({ type: 'EDITINFO' }),
    resitPasswordfun: () => dispatch({ type: 'RESITPASSWORD' })
  }
}

/* End Update State Use REDUX */
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProfileFooter))

import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { client } from "../../auth/Client";
import FloatingAlert from "../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../helpers/bgSliders";
import "./LoginPage.scss";
import Modal from 'react-awesome-modal';
import { setAgencyData} from "../../helpers/agencyTracker";
import axios from "axios";
import {getPhoneByHeader ,setLoginData} from "../../helpers/helpers"

import LoginPageDisplay from "./LoginPage.styled";
const randomBg = bgSliders.getRandomBg();
const url = window.location.hostname.includes("ksa");
const urlEgy = window.location.hostname.includes("egypt");
const urlgamezone = window.location.hostname.includes("ao.playit");
const urlkorek = window.location.hostname.includes("korek");

const urlgamezones = window.location.hostname.includes("gamezones");

const urlGamesroid = window.location.hostname.includes("gamezroid");
 const urlcompetition = window.location.hostname.includes("competition");
 const urlethio = window.location.hostname.includes("ethio");
 const urlMtnYemen= window.location.hostname.includes("gamesstore");
 const urlZainbh = window.location.hostname.includes("zainbh");
 const urlMauritania = window.location.hostname.includes("mauritania");
 const urlyMobile = window.location.hostname.includes("ymobile");
 const urlKoreq= window.location.hostname.includes("koreq");




  //const urlMtnYemen = window.location.hostname.includes("local");



class LoginPage extends Component {
  constructor() {
    super();
    this.state = {
      phone: "",
      password: "",
      errors: false,
      errorMsg: "",
      show: true,
      visibleModalgamezone:false,
      showLoginPage: true,
      termscheck :false,
      phonenum:"",
      phonePlaceholder : "enter_phone_number"

    };

  }

  async openModalgamezone() {
    const check = await this.termsValue();
    const { phone, password } = this.state;
    if(!check && this.isValid(phone, password))
    {
      this.setState({
      visibleModalgamezone: true
    });

    }
    else
    {
      if (localStorage.getItem('gameURL')) {
       //console.log('gameID', localStorage.getItem('gameURL'))
        let gameURL = localStorage.getItem('gameURL');
        this.props.history.push(gameURL)
      } else {
        this.props.history.push("/");
      }

    }
  }

  closeModalgamezone()
  {
    this.setState({
      visibleModalgamezone: false
    });

  }

  async termsValue()
  {
    const { phone } = this.state;
    const termscheck =  await client.Termscheck(phone);
    return termscheck
    
  }

  async  fetchit() {
    var linkheader = "./headercheckyemen.php";
    const num = await getPhoneByHeader(linkheader);
   //console.log(num)
    var phone_h = num;
    if(phone_h != false)
    {
      this.setState({
        phone : phone_h
      })

      this.loginByHeader(phone_h)
    }
   
    //this.loginByHeader(12345)

  const url = window.location.search;
  if (url !== "") {
   //console.log("tracker")
    setAgencyData(url);
  }

  }

  async componentDidMount() {
   /* if(urlcompetition)
    {
      this.setState({
        showLoginPage: false,
      })
      window.location.href= "http://competition.playit.mobi/signup";
      //this.props.history.push("/signup");

    }*/
    if(urlMtnYemen)
    {
      
      this.fetchit();
    }
    if(urlGamesroid)
    {
      this.setState({
        phonePlaceholder : "gamesplaceholder"
      })
    }

    

    if (await this.checkAuth()) {
      this.props.history.push("/");
    }
    /* check if portal = zain-iq  to hidden login form use show stats prop*/
    var url = window.location.href
    if (url.includes('zain-iq')) { this.setState({ show: false }) }
  }



  async loginByHeader(phone)
  {
    const dataOject = {
      MSISDNCG: phone
    }
    const headers = {
      headers: { "egauthorization": "Basic RWd5cHRBcGk6S3N0cmZXTHc4V0VEOFZHd3d5eQ==", "Content-Type": "application/json" }
    }

   //console.log(headers , dataOject)

       const res = await axios.post(`http://www.api.playit.mobi/api/v2/egylogin`, dataOject, headers)
        .then(res => {
          var loged = setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);
          if (loged) {

            window.location.href = "/";

          } else {

          }



        }).catch(error => {

         //console.log(error);
        }

        )

  }

  

  async login(e) {
    e.preventDefault();
    const { phone, password } = this.state;
    if (!this.isValid(phone, password)) {
      this.setState({
        errors: true,
        errorMsg: this.props.t("Empty Phone Number Or Password")
      });
      return;
    }
    const hasAccess = await client.login(phone, password);
//console.log(hasAccess);    
    if (!hasAccess) {
      if(urlGamesroid)
      {
        return this.setState({
          errors: true,
          errorMsg: this.props.t("invalid_phone_password_gamees")
        });

      }
      else if(urlgamezone)
      {
        return this.setState({
          errors: true,
          errorMsg: this.props.t("invalid_phone_password_gz")
        });

      }
      else{
      return this.setState({
        errors: true,
        errorMsg: this.props.t("invalid_phone_password")
      });
    }
    }

    else
    {
      if(urlgamezone) 
      {
        this.openModalgamezone()
      }else if(urlMtnYemen){
        if(hasAccess == "0"){
          this.setState({
          errors: true,
          errorMsg: this.props.t("Empty Phone Number Or Password")
          });
        return;
        }else if(hasAccess == "user expired"){
          this.setState({
            errors: true,
            errorMsg: this.props.t(hasAccess)
            });
          return;
        }else{
          if (localStorage.getItem('gameURL')) {
           //console.log('gameID', localStorage.getItem('gameURL'))
            let gameURL = localStorage.getItem('gameURL');
            this.props.history.push(gameURL)
          } else {
            this.props.history.push("/");
          }
  
        }
      }
      else
      {
        // start redirecting here
        if (localStorage.getItem('gameURL')) {
         //console.log('gameID', localStorage.getItem('gameURL'))
          let gameURL = localStorage.getItem('gameURL');
          this.props.history.push(gameURL)
        } else {
          this.props.history.push("/");
        }

      }
  }

  }


  checkTerms(e)
  {
    e.preventDefault();
    const { phone } = this.state;
    const terms =  client.Terms(phone);
    if(terms)
    {
      this.login(e);
    }
  }

  condition(e)
  {
    this.closeModalgamezone()
    this.props.history.push("/");
  }

  async checkAuth() {
   //console.log(this.props.gamesCo, " this.props.gamesCo")

    const auth = await client.auth();
    return auth;
  }
  isValid(phone, password) {
    return phone && password ? true : false;
  }

  onInputChange(name, value) {
    if(name=== 'phone')
    {
      this.setState({
        phonenum: value
      })

    }


   
    
    var newValue = value;
    // add number '2' to phone number in egypt case 
    var phoneEgy = urlEgy && !value.startsWith('2')

    if (phoneEgy && value.slice(0, 4).includes('011') 
    || phoneEgy && value.slice(0, 4).includes('012') ) {
      newValue = '2' + newValue;
     //console.log("add 2")

    }

    
    var phoneYemen = urlMtnYemen;
    if(phoneYemen && name ==='phone' && !value.startsWith('967'))
    {
      newValue = '967' + newValue
    }
    var phoneKoreq = urlKoreq;
    if(phoneKoreq )
    {
      newValue = '999' + newValue
    }


    // add number '251' to phone number in ethio case
    var phoneEthio = urlethio && value.startsWith('0')
    if(phoneEthio && name ==='phone')
    {
      newValue = newValue.substr(1)
     //console.log('new' , newValue)
    }

    if(urlethio && name=== 'phone' && !value.startsWith('251'))
    {
      newValue = '251' + newValue
    }

    if(urlyMobile && name=== 'phone' && !value.startsWith('967'))
    {
      newValue = '967' + newValue
    }

    if(urlgamezones && name ==='phone')
    {
      if(!value.startsWith('964')){
        newValue = '999964' + newValue
      }

      if(value.startsWith('964')){
        newValue = '999' + newValue
      }
      
    }

   
    // remove number '2' from number in gamesroid case
    var phoneGamesroid = urlGamesroid && value.startsWith('2')

    if (phoneGamesroid && value.slice(0, 4).includes('011') 
    || phoneGamesroid && value.slice(0, 4).includes('012') 
    || phoneGamesroid && value.slice(0, 4).includes('010') 
    || phoneGamesroid && value.slice(0, 4).includes('015') ) {
     //console.log("remove 2")
      newValue = newValue.substr(1)
    }


  //console.log("send phone" , newValue)
   ////console.log(this.state)
    this.setState({
      [name]: newValue,
      errors: false
    });
    //console.log(this.state)
  }
  render() {

   
    const { errors, errorMsg ,phonePlaceholder, showLoginPage} = this.state;
    const { t } = this.props;
    return (
      <LoginPageDisplay
        className=""
        bgImg={urlethio || urlMtnYemen ? "" : randomBg}
      >
        {showLoginPage ? (
          <div className={urlMtnYemen ? "formPage formPageMtn " : "formPage"}>
            <form
              onSubmit={(e) => {
                this.login(e);
              }}
            >
              <div className="formsLayout">
                <div
                  className="header"
                  className={urlMtnYemen ? "header headerMtn " : "header"}
                >
                  <Link to="/">
                    <div className="logo" />
                  </Link>
                </div>
                <div
                  className="smsText"
                  style={this.state.show ? { display: "none" } : {}}
                >
                  <p>يجب عليك الدخول عن طريق الرابط الموجود في SMS </p>
                </div>
                <div className="center">
                  <div
                    className="form"
                    style={this.state.show ? {} : { display: "none" }}
                  >
                   { urlkorek ? <div  className="number-inputs">
                     
                        <input
                          readOnly
                          value={"+964"}
                          className="countryCode input"
                        />
                     

                      <div className="iconInput">
                        <i
                          className={
                            urlMtnYemen ? "icon phone mtnPhone" : "icon phone"
                          }
                        />
                        <input
                          type="text"
                          className="input"
                          value={
                            urlMtnYemen ? this.state.phone : this.state.phonenum
                          }
                          // value= {this.state.phone}
                          autoComplete="phone"
                          placeholder={t(this.state.phonePlaceholder)}
                          onChange={(e) =>
                            this.onInputChange("phone", e.target.value)
                          }
                        />
                      </div>
                    </div> :<div className="iconInput">
                        <i
                          className={
                            urlMtnYemen ? "icon phone mtnPhone" : "icon phone"
                          }
                        />
                        <input
                          type="text"
                          className="input"
                          value={
                            urlMtnYemen ? this.state.phone : this.state.phonenum
                          }
                          // value= {this.state.phone}
                          autoComplete="phone"
                          placeholder={t(this.state.phonePlaceholder)}
                          onChange={(e) =>
                            this.onInputChange("phone", e.target.value)
                          }
                        />
                      </div>
                       }

                    <div className="iconInput">
                      <i
                        className={
                          urlMtnYemen ? "icon lock mtnlock" : "icon lock"
                        }
                      />
                      <input
                        type="password"
                        className="input"
                        placeholder={t("enter_password")}
                        autoComplete="password"
                        onChange={(e) =>
                          this.onInputChange("password", e.target.value)
                        }
                      />
                    </div>

                    {urlZainbh || urlGamesroid || urlMauritania ? (
                      ""
                    ) : (
                      <div className="forgotpass">
                        <Link to="forget-password">
                          <Trans>login.forgot_password</Trans>
                        </Link>
                      </div>
                    )}
                  </div>
                </div>

                <div
                  className="footer"
                  style={this.state.show ? {} : { display: "none" }}
                >
                  <div className="normBtn">
                    <button className="btn">
                      <Trans>sign_in</Trans>
                    </button>
                  </div>

                  {
                    <p>
                      <Trans>login.don’t_have_account</Trans>{" "}
                      <Link to="signup">
                        <Trans>sign_up</Trans>
                      </Link>
                    </p>
                  }

                  <Modal
                    visible={urlgamezone && this.state.visibleModalgamezone}
                    width="350"
                    height="500px"
                    effect="fadeInUp"
                    padding="0"
                    onClickAway={() => this.closeModalgamezone()}
                  >
                    <div className="gamezonemodal">
                      <h2>
                        <Trans>GameZone</Trans>
                      </h2>
                      <p>
                        <Trans>
                          DEAR SUBSCRIBER GAME ZONE, ACCEPT THE TERMS AND
                          CONDITIONS
                        </Trans>
                      </p>
                      <img src="/assets/images/gamepopup.png" alt="img" />
                      <p>
                        <a href="http://ao.playit.mobi/tos" target="_blank">
                          <Trans>TERMS AND CONDITIONS</Trans>
                        </a>
                      </p>
                      <div>
                        <button
                          className="btn"
                          onClick={(e) => {
                            this.checkTerms(e);
                          }}
                        >
                          <Trans>ACCEPT</Trans>
                        </button>
                        <button
                          className="btn"
                          onClick={(e) => {
                            this.condition(e);
                          }}
                        >
                          <Trans>TERMS AND CONDITIONS</Trans>
                        </button>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </form>
            {errors ? (
              <FloatingAlert type="error">{errorMsg}</FloatingAlert>
            ) : (
              ""
            )}
          </div>
        ) : null}
      </LoginPageDisplay>
    );
  }
}



/* End Update State Use REDUX */
export default withTranslation()(LoginPage)



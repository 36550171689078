import axios from "axios";
import React, { useEffect, useState } from "react";
import { Trans, withTranslation } from "react-i18next";
import Loader from "../../../../components/Loader/Loader";
import envo from "../../../../environments/environmentProd";
import FloatingAlert from "../../../../components/FloatingAlert/FloatingAlert";
import Confirm from "./components/Confirm";
import GameDetails from "./components/GameDetails";
import Play from "./components/Play";
import "./GameDetailsPage.scss";
import GameDetailsPageDisplay from "./GameDetailsPage.styled";
import { setLoginData } from "../../../../helpers/helpers";

const apiUrl = envo.apiUrl;

//TODO: Add solid star icon for favourite game
function ActionGameDetailsPage(props) {
  const [confirmState, setConfirmState] = useState(false);
  const [playState, setPlayState] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [gameState, setGameState] = useState({});
  const [errorState, setErrorState] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const gameID = props.match.params.gameID;
  const userID = props.match.params.userID;

  //console.log(gameID,userID);

  useEffect(() => {
    async function fetchData() {
      setLoadingState(true);

      if (userID) {
        var paramsuser = {
          UOLID: userID,
        };
        const res = await axios.post(
          "http://www.api.playit.mobi/api/v2/login",
          paramsuser
        );
        //console.log("res" , res.data)
        if (res.data.status === true) {
          if (res.data.user_id) {
            //console.log("task")
            setLoginData(
              res.data.user_id,
              res.data.token,
              res.data.user_name,
              res.data.photo,
              res.data.operator_id,
              res.data.user_email,
              res.data.msisdn
            );
          }
        }
      }

      const options = {
        headers: { AUTHORIZATION: `Bearer ${localStorage.getItem("token")}` },
      };
      const params = {
        id: gameID,
      };
      try {
        const res = await axios.post(`${apiUrl}/playit/games`, params, options);
        if (!res.data) return false;
        const data = res.data;

        if (data.status !== "success") {
          return false;
        }
        setGameState(data.game);
        setLoadingState(false);
      } catch (err) {
        setLoadingState(false);
        setErrorState(true);
        setErrorMsg(props.t("network_failed"));
      }
    }
    fetchData();
  }, [gameID]);
  useEffect(() => {
    if (playState) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [playState]);

  return (
    <GameDetailsPageDisplay className={playState && "playing"}>
    {/* <p>test</p> */}
      {confirmState || playState ? (
        <>
          {confirmState && (
            <>
              <Confirm
                setConfirmState={setConfirmState}
                apk={gameState.game_folder}
                name={gameState.game_name}
              />
            </>
          )}
          {playState && (
            <Play gameIframe={gameState.game_folder} gameID={gameID} />
          )}
        </>
      ) : (
        <>
          {loadingState || errorState ? (
            <>
              {loadingState && <Loader type="gamePage" />}
              {errorState && (
                <>
                  <FloatingAlert type="error">
                    {errorMsg}{" "}
                    <a className="reload" href=" ">
                      <Trans>try_again</Trans>
                    </a>
                  </FloatingAlert>
                </>
              )}
            </>
          ) : (
            <GameDetails
              game={gameState}
              setPlayState={setPlayState}
              setConfirmState={setConfirmState}
              history={props.history}
            />
          )}
        </>
      )}
    </GameDetailsPageDisplay>
  );
}

export default withTranslation()(ActionGameDetailsPage);

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Trans, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ConfirmationCodeComponent from "../../components/ConfirmationCodeComponent/ConfirmationCodeComponent";
import "./UnsubscribePage.scss";
import UnsubscribePageDisplay, { Unsubscribe } from "./UnsubscribePage.styled";
import { client } from './../../auth/Client';
import Modal from 'react-awesome-modal';
import FloatingAlert from "../../components/FloatingAlert/FloatingAlert";
import { clearLoginData } from "../../helpers/helpers";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
const phoneNum = localStorage.getItem("phoneNum");
const url = window.location.hostname.includes("ksa");
const urlEgy = window.location.hostname.includes("egypt");
const urlgamezroid = window.location.hostname.includes("gamezroid");
const urlasiacell =window.location.hostname.includes("asiacell");
const urljawwalgamezroid = window.location.hostname.includes("jawwal.gamezroid");
const urltdzo = window.location.hostname.includes("tdzo");
const urldzo = window.location.hostname.includes("dzo");
const urlgamesstore = window.location.hostname.includes("gamesstore");
const urlMauritania = window.location.hostname.includes("mauritania");
const urlLibiana = window.location.hostname.includes("libya");


// var lo =  Unsubscribe (){

// }
function UnsubscribePage({ t, history, props }) {

  const [showOnlyverif, setshowOnlyverif] = useState(false);
  const [showverif, setShowverif] = useState(false);
  const [verifyCode, setVerifyCode] = useState('');
  const [errorMesg, setErrorMesg] = useState(false);
  const [unsuberrorMesg, setunsuberrorMesg] = useState(false);
  const [unsubmesg, setunsubmesg] = useState('');

  const [verfiErrorMesg, setVerfiErrorMesg] = useState(false);
  const [visablemodal, setvisablemodal] = useState(false);
  const location = useLocation()

  function unSub() {

   //console.log("un su");
    if (urlasiacell) {
     // setShowverif(true)
      setshowOnlyverif(true);
    }
 

    if (url) {
      axios.get(`http://api.playit.mobi/api/v2/mobimind/sms/code?msisdn=${phoneNum}`)
        .then(res => {
          ////console.log(res.data["code"])
          //***********  */
          // eslint-disable-next-line default-case
          switch (res.data["code"]) {
            case 801281:
              window.location.href = 'sms:801281;?&body=U 1';
              break;
            case 708370:
              window.location.href = 'sms:708370;?&body=U 1';
              break;
            case 670611:
              window.location.href = 'sms:670611;?&body=U 10';
              break;
          }
        }).catch(error => console.error(error))
    }

 /*   if (urlEgy) {
      const operatorId = localStorage.getItem("operatorId")
      const phone = localStorage.getItem("phoneNum");
       switch (operatorId) {
        case 'TPET5SBsm7':
          window.location.href = `http://galaxylp.mobi-mind.net/Home/Unsubscribe?Id=918,MM,20,2695,17,,0,60202,11260&MM_MSISDN=${phone}`;
          break;
        case 'OT50jUjoa7':
          window.location.href = `http://galaxylp.mobi-mind.net/Home/Unsubscribe?Id=918,MM,20,2695,17,,0,60203,11258&MM_MSISDN=${phone}`;
          break;
        case 'FZ6DCKgZLB':
          window.location.href = 'http://sso.orange.com/mondiamedia_subscription/?method=unsubscribe&serviceId=3945';
          break;
      }
    }
*/

if(urljawwalgamezroid)
{
  const id = localStorage.getItem('phoneNum');
  const apiurl = "http://api.playit.mobi/api/v2/jawwal/gamezroid/unSubscribe?msisdn="+id;
  const config = {
    method: 'get',
    url: apiurl
  }
    axios(config)
      .then(res => {
       //console.log(res.data.status)
        if (res.data && res.data !== "" && res.data.status) {
         // setShowverif(true)
         client.logout();
         location.pathname.includes('action-games') ? history.push('/action-games') : location.pathname.includes('brain-games') ? history.push('/brain-games') :history.push("/");
         window.location.reload();

        }
        else {
          setunsuberrorMesg(true)
          setunsubmesg(res.data.data)
          setErrorMesg(true)
        }
      })
      .catch(error => { console.log(error)} ) 
}

if(urlMauritania)
{
  const subscriptionContractId = localStorage.getItem('subscriptionContractId');
      const options = { subscriptionContractId };
      const apiUrl = "http://www.api.playit.mobi/api/v2/tpayMit/cancel"

      axios.post(apiUrl, options)
        .then(res => {
         //console.log(res)
          if (res.data['status']) {
            setShowverif(true)
          }
          else {
            setErrorMesg(true)
          }
        })
        .catch(error => console.log(error))

}

if(urlLibiana)
{
  const msisdn = localStorage.getItem('phoneNum');
  const apiUrl = "http://www.api.playit.mobi/api/v2/connex-libya/send-otp-unsubscribe?msisdn="+msisdn

      axios.get(apiUrl)
        .then(res => {
         //console.log(res)
          if (res.data.status == true) {
            setShowverif(true)
          }
          else {
            setErrorMesg(true)
          }
        })
        .catch(error => console.log(error))

}


    if (urlgamezroid && !urlasiacell) {
      const subscriptionContractId = localStorage.getItem('subscriptionContractId');
      const options = { subscriptionContractId };
      const apiUrl = "http://api.playit.mobi/api/v1/tpay/cancel"

      axios.post(apiUrl, options)
        .then(res => {
         //console.log(res)
          if (res.data['status']) {
            setShowverif(true)
          }
          else {
            setErrorMesg(true)
          }
        })
        .catch(error => console.log(error))
    }

    // if(urldzo && !urltdzo)
    // {
    //   const id = localStorage.getItem('phoneNum');
    //   const apiurl = "http://www.api.playit.mobi/api/v1/mondia/deleteSubDetails?uuid="+id;
    //   const config = {
    //     method: 'get',
    //     url: apiurl
    //   }
    //     axios(config)
    //       .then(res => {
    //        //console.log(res.data.status)
    //         if (res.data && res.data !== "" && res.data.status) {
    //          // setShowverif(true)
    //          client.logout();
    //          history.push("/");
    //          window.location.reload();
  
    //         }
    //         else {
    //           setunsuberrorMesg(true)
    //           setunsubmesg(res.data.data)
    //           setErrorMesg(true)
    //         }
    //       })
    //       .catch(error => console.log(error)) 
    // }
    
    if(urldzo)
    {
      const id = localStorage.getItem('userId');
      const apiurl = "http://api.playit.mobi/api/v2/trendy-algeria/unsubscribe?userId="+id;
      const config = {
        method: 'get',
        url: apiurl
      }
        axios(config)
          .then(res => {
           //console.log(res.data.status)
            if (res.data && res.data !== "" && res.data.status) {
             // setShowverif(true)
             client.logout();
             location.pathname.includes("action-games")
               ? history.push("/action-games")
               : location.pathname.includes("brain-games")
               ? history.push("/brain-games")
               : history.push("/");
             window.location.reload();
  
            }
            else {
              setunsuberrorMesg(true)
              setunsubmesg(res.data.data)
              setErrorMesg(true)
            }
          })
          .catch(error => console.log(error)) 
    }

    if(urlEgy)
    {
      const id = localStorage.getItem('phoneNum');
      const apiurl = "http://www.api.playit.mobi/api/v2/egypt/deleteSubDetails?uuid="+id;
      const config = {
        method: 'get',
        url: apiurl
      }
        axios(config)
          .then(res => {
           //console.log(res.data.status)
            if (res.data && res.data !== "" && res.data.status) {
             // setShowverif(true)
             client.logout();
             setvisablemodal(true);

             const timer = setTimeout(() => {
              location.pathname.includes("action-games")
                ? history.push("/action-games")
                : location.pathname.includes("brain-games")
                ? history.push("/brain-games")
                : history.push("/");
              window.location.reload();

            }, 4000);
            clearTimeout(timer);
  
            }
            else {
              setunsuberrorMesg(true)
              setunsubmesg(res.data.data)
              setErrorMesg(true)
              
            }
          })
          .catch(error => console.log(error)) 
    }

    
    if(urlgamesstore)
    {
      const id = localStorage.getItem('phoneNum');
      const apiurl = "http://api.playit.mobi/api/v2/mtnyemn/unsubscribe?phone="+id;
      const config = {
        method: 'get',
        url: apiurl
      }
        axios(config)
          .then(res => {
           //console.log(res.data.status)
            if (res.data && res.data !== "" && res.data.status) {
             // setShowverif(true)
             client.logout();
             location.pathname.includes("action-games")
               ? history.push("/action-games")
               : location.pathname.includes("brain-games")
               ? history.push("/brain-games")
               : history.push("/");
             window.location.reload();
  
            }
            else {
              setunsuberrorMesg(true)
              setunsubmesg(res.data.data)
              setErrorMesg(true)
            }
          })
          .catch(error => console.log(error)) 
    }

    

    

    //***********  */
    setSure(sure)
    // window.location.replace('/');

  }

  const onInputChange = (name, value) => {
    if (name == 'verifycode') {
      document.getElementById("verifyInput").addEventListener("keypress", function (evt) {
        if (evt.which < 48 || evt.which > 57) {
         //console.log("prevent")
          evt.preventDefault();
        }
      });
      setVerifyCode(value)
    }
  }

  const verify = async e => {
    e.preventDefault();
    if(urlasiacell)
    {
      const options = 
      {
        msisdn: localStorage.getItem("phoneNum"),
        lang: localStorage.getItem("i18nextLng"),
        token:localStorage.getItem("token")
        }
      const apiUrl = "http://api.playit.mobi/api/v2/asiacell/unsubscribe"
      axios.post(apiUrl, options)
        .then(res => {
         //console.log(res.data.status)
          if (res.data.status) {
           // setShowverif(true)
           client.logout();
           location.pathname.includes("action-games")
             ? history.push("/action-games")
             : location.pathname.includes("brain-games")
             ? history.push("/brain-games")
             : history.push("/");
           window.location.reload();

          }
          else {
            setErrorMesg(true)
          }
        })
        .catch(error => console.log(error))
    }
   
    else if(urlLibiana)
    {
      
        var userId = localStorage.getItem("userId");
        var pinCode = verifyCode;
      
      const apiUrl = `http://api.playit.mobi/api/v2/connex-libya/unsubscribe?userId=${userId}&pincode=${pinCode}`

      axios.get(apiUrl)
        .then(res => {
         //console.log(res.data.status)
          if (res.data.status) {
           // setShowverif(true)
           client.logout();
           location.pathname.includes("action-games")
             ? history.push("/action-games")
             : location.pathname.includes("brain-games")
             ? history.push("/brain-games")
             : history.push("/");
           window.location.reload();

          }
          else {
            setErrorMesg(true)
          }
        })
        .catch(error => console.log(error))
    }
   
    else{
    try {
      const subscriptionContractId = localStorage.getItem('subscriptionContractId');
      //var subscriptionContractId = 223579649
      var pinCode = verifyCode;
      const url = "http://api.playit.mobi/api/v2/tpayMit/verifyCancel";
      const options = {
        subscriptionContractId,
        pinCode
      };
      const res = await axios.post(url, options);
      if (res.data['status']) {
        setvisablemodal(true);
      }
      else {
        setVerfiErrorMesg(true)
      }
    } catch (err) {
     //console.log(err);

    }
  }

  };

  const resend = async e => {
    e.preventDefault();
    //const subscriptionContractId = 223579649
    const subscriptionContractId = localStorage.getItem('subscriptionContractId')
    const options = { subscriptionContractId }
    axios.post("http://api.playit.mobi/api/v1/tpay/cancel", options)
      .then(res => {
       //console.log(res)

      })
      .catch(error => console.log(error))
  }

  const closeModalgamezorid = () => {
    setvisablemodal(false)
    client.logout();
    window.location.reload()
    //this.props.history.push("/login");  // not work
    if(urlEgy){
      window.location.href = "http://egypt.playit.mobi/"
    }else{
      window.location.href = "http://mauritania.playit.mobi/login"
    }


  };


  const [sure, setSure] = useState(false);
  return (
    <UnsubscribePageDisplay>


      {errorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>Unsubscribe.UNSUBSCRIBE_ERROR</Trans>
        </FloatingAlert>
      ) : null}

    {unsuberrorMesg ? (
            <FloatingAlert type={"error"}>
              <Trans>{{unsubmesg}}</Trans>
            </FloatingAlert>
          ) : null}

      {verfiErrorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>Unsubscribe.UNSUBSCRIBE_ERRORVERFIY</Trans>
        </FloatingAlert>
      ) : null}
      {sure ? (
        <ConfirmationCodeComponent
          pageName={"unsubscribe"}
          topHeader={"navbar"}
          history={history}
          actionSuccessEndPoint={"/"}
          transText={{
            verification_code: t("verfication.verification_code"),
            didnotRecieve: t("verfication.didn't_receive_code"),
            resend_code: t("verfication.resend_code"),
            actionBtn: t("unsubscribe"),
            accept: t("accept_unsubscribe"),
            terms_and_conditions: t("terms_and_conditions")
          }}
        />
      ) : (
          <>
            <Unsubscribe>
              {!showverif && !showOnlyverif?
                <div>
                  <div className="sad"></div>
                  <p className="confirmQues">
                    <Trans>unsubscribeConfirm.you_want_unsubscribe?</Trans>
                  </p>
                  {/* <p>
                <Trans>unsubscribeConfirm.what_about_fun?</Trans>
              </p> */}
                  <div className="normBtn">
                    <Link className="btn" to={location.pathname.includes('action-games') ? '/action-games' : location.pathname.includes('brain-games') ? '/brain-games' :'/'}>
                      <Trans>back_home</Trans>
                    </Link>
                  </div>
                  <p className="unsubscribe link" onClick={() => unSub()}>
                    <Trans>unsubscribe</Trans>
                  </p>
                </div> : null}

              <div className="center verfycode">

                {showverif ?
                  <div className="">
                    <p><Trans>verifycode</Trans></p>
                    <input
                      type="text"
                      className="input"
                      value={verifyCode}
                      maxLength="6"
                      id="verifyInput"
                      placeholder={t("Enter_Unverifycode")}
                      onChange={e => onInputChange("verifycode", e.target.value)}
                    />
                    <br></br>
                    <br></br> <br></br>
                    <button
                      className="btn"
                      disabled={verifyCode ? "" : "disabled"}
                      onClick={(e) => {
                        verify(e);
                      }} >
                      {t("UnVerify")}
                    </button>
                    <br></br>
                    <button
                      className="btn resend"

                      onClick={(e) => {
                        resend(e);
                      }} >
                      {t("Resend")}
                    </button>


                  </div> : null
                }

                {showOnlyverif ?
                  <div className="">
                    <br></br>
                    <br></br>
                    <br></br> <br></br>
                    <button
                      className="btn"
                      onClick={(e) => {
                        verify(e);
                      }} >
                      {t("Verifyasicell")}
                    </button>
                    <br></br>
                   
                  </div> : null
                }

                <Modal
                  visible={visablemodal}
                  width="650"
                  height="385"
                  effect="fadeInUp"
                  padding="0"
                  onClickAway={() => closeModalgamezorid()}
                >
                  <div className="gamezroidmodal">
                    <div>
                      <div className="sad unsubSad"></div>
                      <h3>
                        <Trans>Unsubscribed successfully</Trans>
                      </h3>
                      <p>
                        <Trans>you have Unsubscribed successfully from the service you can always subscribe again to the service at any time</Trans>
                      </p>
                    </div>
                  </div>


                </Modal>
              </div>
            </Unsubscribe>
          </>
        )}
    </UnsubscribePageDisplay>
  );
}
export default withTranslation()(UnsubscribePage);

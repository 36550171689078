import React from "react";
import ReactDOM from "react-dom";
import App from "./App.jsx";
import { Provider } from "react-redux";
import { createStore } from "redux";
import "./assets/scss/main.scss";
import "./i18n/i18n";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import "./theming/themes.scss";
import reducer from "./components/reducers/countReducer";
import { ClearBrowserCacheBoundary } from 'react-clear-browser-cache';
// import reportWebVitals from './reportWebVitals';

import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import queryString from "query-string";
import AuthProvider from "./ContextApi/AuthProvider.jsx";


//yousry lang
const params = queryString.parse(window.location.search);
if (params.lang) {
 //console.log("lang");
  if(localStorage.getItem("i18nextLng") != params.lang){
    localStorage.setItem("i18nextLng", params.lang);
    window.location.reload();
  }
  //localLang.checklocalLanguage();
}

// optional configuration
const options = {
    // you can also just use 'bottom center'
    position: positions.TOP_CENTER,
    timeout: 5000000000,
    offset: '30px',
    // you can also just use 'scale'
    transition: transitions.SCALE,
    type: 'success',
    containerStyle:{
        background:"red"
    }
}


const store = createStore(reducer)
ReactDOM.render(
  <Provider store={store}>
    <AuthProvider>
      <ClearBrowserCacheBoundary auto fallback="Loading" duration={60000}>
        <AlertProvider template={AlertTemplate} {...options}>
          <App />
        </AlertProvider>
      </ClearBrowserCacheBoundary>
    </AuthProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

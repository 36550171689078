import React, { Component } from "react";
import SingleGame from "../SingleGame/SingleGame";
import "./GameList.scss";
import GameListDisplay from "./GameList.styled";

export default class GameList extends Component {
  render() {
    const { games } = this.props;
    const gamesList = games.map((i, k) => {
      return (
        <SingleGame
          key={`games-${k}`}
          img={i.img}
          name={i.name}
          namrAr={i.name_ar}
          gameID={i.id}
        ></SingleGame>
      );
    });
    return <GameListDisplay>{gamesList}</GameListDisplay>;
  }
}

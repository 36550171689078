import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { client } from './../../auth/Client';
import './LogoutPage.scss';
import LogoutPageDisplay from './LogoutPage.styled';
import AuthContext from '../../ContextApi/AuthContext';

const urlZainbh = window.location.hostname.includes("zainbh");
const urlStcbh = window.location.hostname.includes("stcbh");


class LogoutPage extends Component {
  static contextType = AuthContext;

  async logout(e) {
    e.preventDefault();
    await client.logout();
	this.context.logout()
    if (urlZainbh || urlStcbh) {
      window.location.href.includes("brain-games")
        ? this.props.history.push("/brain-games")
        : window.location.href.includes("action-games")
        ? this.props.history.push("/action-games")
        : this.props.history.push("/");
      window.location.reload();
    } else {
      window.location.href.includes("brain-games")
        ? this.props.history.push("/brain-games/login")
        : window.location.href.includes("action-games")
        ? this.props.history.push("/action-games/login")
        : this.props.history.push("/login");
    }
  }
  render() {
    return (
      <>
        <LogoutPageDisplay>
          <p className="logoutQues">
            <Trans>logout.you_want_to_exit</Trans>
          </p>
          <div className="normBtn">
            <button
              onClick={(e) => {
                window.location.href.includes("brain-games")
                  ? this.props.history.push("/brain-games")
                  : window.location.href.includes("action-games")
                  ? this.props.history.push("/action-games")
                  : this.props.history.push("/");
              }}
              className="btn"
            >
              <Trans>logout.keep_me_logged</Trans>
            </button>
          </div>
          <div>
            <button className="btn secondary" onClick={(e) => this.logout(e)}>
              <Trans>logout.exit</Trans>
            </button>
          </div>
        </LogoutPageDisplay>
      </>
    );
  }
}
export default withTranslation()(LogoutPage)
